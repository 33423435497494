import React from 'react';
import { AppToastsProps } from '@wix/social-groups-api';
import { AppToastsController } from '../../controllers';

const AppToastsContext = React.createContext<AppToastsProps>(
  AppToastsController.emptyState,
);

export const AppToastsProvider = AppToastsContext.Provider;
export const AppToastsConsumer = AppToastsContext.Consumer;
