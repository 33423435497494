import { IGroup } from '../types/IGroup';

export function groupCoverUrl(
  g: Pick<IGroup, 'logo' | 'getScaledLogo'>,
  scale: { targetWidth?: number; targetHeight?: number } = {},
) {
  if (!g.logo) {
    return;
  }
  const { height, width, mediaId } = g.logo;
  const { targetWidth, targetHeight } = scale;
  if (targetWidth && targetHeight) {
    return g.logo ? g.getScaledLogo(targetWidth, targetHeight) : '';
  }
  if (targetHeight && height) {
    const w = (width! * targetHeight) / height;
    return g.logo ? g.getScaledLogo(w, targetHeight) : '';
  }
  if (g.logo?.isWixmpUrl) {
    return g.getScaledLogo(width! * 0.5, height! * 0.5);
  }
  return mediaId!;
}
