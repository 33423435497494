import React from 'react';

export const Secret: React.FC = (props) => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" {...props}>
    <g
      id="Icons/Secret"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <path
        d="M5.32539569,5.6203717 L7.64734041,7.60978149 C9.02341181,6.53659383 10.4742983,6 12,6 C14.9455187,6 17.6121853,8 20,12 C19.0809051,13.5396419 18.1204966,14.7829717 17.1187744,15.7299893 L19.3253957,17.6203717 L18.6746043,18.3796283 L16.3516578,16.3909997 C14.975884,17.4636666 13.5253314,18 12,18 C9.05448133,18 6.38781467,16 4,12 C4.91878956,10.4608696 5.87886529,9.21785444 6.88022719,8.27095463 L4.67460431,6.3796283 L5.32539569,5.6203717 Z M5.26489412,11.8562678 L5.174,12 L5.26489412,12.1437322 C7.30471375,15.3186137 9.47312121,16.8965768 11.7698948,16.9950772 L12,17 C13.2283092,17 14.4201788,16.581108 15.5755751,15.7255578 L13.9201906,14.3050637 C13.3999012,14.7389529 12.730436,15 12,15 C10.3431458,15 9,13.6568542 9,12 C9,11.434936 9.15622473,10.9063603 9.42783849,10.4551085 L7.64383027,8.9242335 C6.83232432,9.67567538 6.03934156,10.6508775 5.26489412,11.8562678 Z M12,7 C10.7713363,7 9.5791333,7.41913379 8.42342454,8.27518304 L10.078798,9.69578011 C10.599231,9.26138559 11.2690906,9 12,9 C13.6568542,9 15,10.3431458 15,12 C15,12.5654807 14.8435448,13.0944193 14.5715604,13.5458897 L16.3561697,15.0757665 C17.1676757,14.3243246 17.9606584,13.3491225 18.7351059,12.1437322 L18.825,12 L18.7351059,11.8562678 C16.6952862,8.68138631 14.5268788,7.10342319 12.2301052,7.00492284 L12,7 Z M10,12 C10,13.1045695 10.8954305,14 12,14 C12.4258847,14 12.8206784,13.8668842 13.145044,13.6399898 L10.2037757,11.1194454 C10.0732811,11.3851362 10,11.6840102 10,12 Z M12,10 C11.5741153,10 11.1793216,10.1331158 10.854956,10.3600102 L13.7957303,12.8815596 C13.926534,12.6156176 14,12.3163882 14,12 C14,10.8954305 13.1045695,10 12,10 Z"
        fill="currentColor"
        fillRule="nonzero"
      />
    </g>
  </svg>
);
