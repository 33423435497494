import { AmbassadorHTTPError } from '@wix/ambassador/runtime/http';
import { ApplicationError } from '../../../../common/api/errors/ApplicationError';

export enum ErrorOrigin {
  Group = 'Group',
  Feed = 'Feed',
  JoinGroup = 'JoinGroup',
}

export enum ApiErrorCode {
  ACCESS_RESTRICTION_NOT_SATISFIED = 'ACCESS_RESTRICTION_NOT_SATISFIED',
  ACCESS_RESTRICTION_PAID_PLANS = 'ACCESS_RESTRICTION_PAID_PLANS',
  ACCESS_RESTRICTION_PAID_PLANS_FUTURE_PLAN_EXISTS = 'ACCESS_RESTRICTION_PAID_PLANS_FUTURE_PLAN_EXISTS',
  ACCESS_RESTRICTION_PAID_PLANS_NOT_INSTALLED = 'ACCESS_RESTRICTION_PAID_PLANS_NOT_INSTALLED',
}

export interface IErrorEvent {
  code: number;
  origin: ErrorOrigin;
  apiError?: ApiErrorCode;
  applicationError?: ApplicationError;
}

export function errorEventFromAmbassador(
  e: AmbassadorHTTPError,
  origin: ErrorOrigin,
): IErrorEvent {
  try {
    const {
      response: { data },
      httpStatus,
    } = e;
    const error = new ApplicationError(data);
    return {
      origin,
      apiError: error.getCode() || error.message,
      code: httpStatus,
      applicationError: error,
    };
  } catch (e) {
    return { code: 1, origin };
  }
}
