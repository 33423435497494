import React from 'react';
import { ButtonProps, PRIORITY } from 'wix-ui-tpa/Button';
import { DATA_HOOKS } from './dataHooks';
import { Button } from '../../../../common/components/Button';
import { useUser } from '../../../../common/context/user/useUser';
import { UserStatus } from '../../../../common/context/user/IUserContext';
import { useGroupsBi } from '../hooks/useGroupsBi';

interface Props extends ButtonProps {
  biOrigin: string;
}

export const CreateGroupButton: React.FC<Props> = ({
  biOrigin,
  children,
  onClick,
  ...rest
}) => {
  const { userPermissions, userActions, userStatus } = useUser();
  const { groupsCreateGroupClick } = useGroupsBi();
  if (!userPermissions.canCreateGroup) {
    return null;
  }
  const handleClick = (e: any) => {
    switch (userStatus) {
      case UserStatus.UNKNOWN:
        userActions.promptLogin({ modal: true });
        break;
      case UserStatus.PRIVATE_PROFILE:
        // TODO: openProfileModal
        throw new Error('Method not implemented.');
        break;
      default:
        groupsCreateGroupClick(biOrigin);
        onClick?.(e);
    }
  };
  return (
    <Button
      priority={PRIORITY.secondary}
      data-hook={DATA_HOOKS.createNewButton}
      onClick={handleClick}
      {...rest}
    >
      {children}
    </Button>
  );
};

CreateGroupButton.displayName = 'CreateGroupButton';
