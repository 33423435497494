import { useContext } from 'react';
import { AppDataContext } from '../../contexts/AppData/AppData';

export function useSiteNavigation() {
  const { siteNavigation } = useContext(AppDataContext);
  if (!siteNavigation) {
    return [{ href: '/' }, { href: '' }]; // TODO: handle siteNavigation smth wrong
  }
  return siteNavigation;
}
