import React from 'react';
import { AppRootActions } from '../Widget/App/types';
import { GroupsActionsConsumer } from './GroupsActionsContext';

export interface WithGroupsActionsProps {
  actions: AppRootActions;
}

export const withGroupsActions = <P extends object>(
  WrappedComponent: React.ComponentType<P>,
) => {
  return class WithGroupsActions extends React.Component<
    P & WithGroupsActionsProps
  > {
    render() {
      return (
        <GroupsActionsConsumer>
          {(actions) => <WrappedComponent {...this.props} actions={actions} />}
        </GroupsActionsConsumer>
      );
    }
  };
};

export default withGroupsActions;
