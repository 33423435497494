import React from 'react';
import { AppData } from './IAppData';
import { Button } from '../../types/button';
import { ISiteNavigation } from '../../controllers/main/SiteNavigation';

export type AppDataWithStyles = AppData & {
  siteNavigation?: ISiteNavigation[];
};
export const AppDataContext = React.createContext<AppDataWithStyles>({
  instance: '',
  locale: undefined as any,
  instanceId: undefined as any,
  activeButton: Button.PRIMARY,
});
AppDataContext.displayName = 'AppDataContext';
