// https://bo.wix.com/wix-docs/client/viewer-platform---ooi/articles/component#viewer-platform---ooi_articles_component_props

export enum ViewMode {
  EDITOR = 'Editor',
  PREVIEW = 'Preview',
  SITE = 'Site',
}
// Desktop,Mobile,Tablet
export enum FormFactor {
  MOBILE = 'Mobile',
  DESKTOP = 'Desktop',
  TABLET = 'Tablet',
}

export interface Dimensions {
  top: number;
  bottom: number;
  left: number;
  right: number;
  width: number;
  height: number;
  position: string;
}
