import React from 'react';
import { Spinner } from '../../Spinner';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { Restricted } from './Restricted';
import { Event } from '@wix/ambassador-events-v1-event/types';
import { filterEvents } from '../../Event/helpers';
import { getEventPage } from '../../Event/getEventPage';
import { RestrictedListModal } from './RestrictedListModal';
import { useUser } from '../../../context/user/useUser';
import { UserStatus } from '../../../context/user/IUserContext';

function goToEventPage(eventPage: string | undefined) {
  if (!eventPage) {
    return;
  }
  window.open(eventPage, '_blank'); // TODO: blank?
}

export const EventsRestricted: React.FC = () => {
  const { userActions, userStatus, userRequestResponse } = useUser();
  const [openModal, setOpenModal] = React.useState(false);

  const { t } = useTranslation();

  if (!userRequestResponse?.events) {
    return <Spinner offset="M" label={t('groups-web.loading')} />;
  }

  const [upcoming, past] = filterEvents(userRequestResponse?.events);

  function viewEvents(_events: Event[]) {
    if (userStatus === UserStatus.UNKNOWN) {
      userActions.promptLogin({ modal: true });
      console.warn('this shouldn`t be');
      return;
    }
    if (_events.length === 1) {
      const eventPage = getEventPage(_events[0].eventPageUrl);
      goToEventPage(eventPage);
    } else {
      setOpenModal(true);
    }
  }

  function closeModal() {
    setOpenModal(false);
  }

  return (
    <>
      <Restricted upcoming={upcoming} past={past} onViewEvents={viewEvents} />
      {upcoming.length > 1 ? (
        <RestrictedListModal
          events={upcoming}
          isOpen={openModal}
          onRequestClose={closeModal}
        />
      ) : null}
    </>
  );
};

EventsRestricted.displayName = 'EventsRestricted';
