import React from 'react';
import {
  ToggleSwitch as TPAToggleSwitch,
  ToggleSwitchProps,
} from 'wix-ui-tpa/ToggleSwitch';

import { st, classes } from './ToggleSwitch.st.css';
import { Theme } from '../../context/theme';

export const ToggleSwitch: React.FC<ToggleSwitchProps & Theme> = (props) => (
  <TPAToggleSwitch
    {...props}
    className={st(
      classes.root,
      { bw: !!props.forceBlackAndWhite },
      props.className,
    )}
  />
);
ToggleSwitch.displayName = 'ToggleSwitch';
