import { widgetSettingsParams } from '../../common/settings/groups-list/widgetSettings';
import { LayoutType } from '../../common/settings/groups-list/settingsConstants';

export default {
  ...(widgetSettingsParams as any),
  groupListLayout: {
    getDefaultValue: () => LayoutType.sideBySide,
    key: 'groupListLayout',
  },
};
