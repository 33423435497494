export enum PubSubEventTypes {
  UPDATE_GROUP_EVENT = 'updateGroup',
  WILL_UPDATE_GROUP_EVENT = 'willUpdateGroup',
  DID_UPDATE_GROUP_EVENT = 'didUpdateGroup',
  UPDATE_MEMBERS_EVENT = 'updateMembers',
  UPDATE_CONTEXT_TOKENS_EVENT = 'updateContextTokens',
  DID_UPDATE_CONTEXT_TOKENS_EVENT = 'didUpdateContextTokens',
  FEED_AUTHORS_IDS = 'feedAuthorsIds',
  CONTROLLER_ERROR = 'controllerError',
  COMMENTS_CHANGED = 'commentsChanged',
  JOIN_GROUP = 'joinGroup',
  LEAVE_GROUP = 'leaveGroup',
  GROUP_REQUEST_ACTION = 'groupRequestAction',
}
