import { MembersLabelTranslations } from '../../settings/groupMembersLabel';

export const MembersLabelDict: MembersLabelTranslations = {
  Members: 'groups-web.settings.TextTab.members.text',
  Students: 'groups-web.settings.TextTab.students.text',
  Coworkers: 'groups-web.settings.TextTab.coworkers.text',
  Friends: 'groups-web.settings.TextTab.friends.text',
  Gamers: 'groups-web.settings.TextTab.gamers.text',
  Custom: 'groups-web.settings.TextTab.custom.text',
};

export const MembersLabelWithCountDict: MembersLabelTranslations = {
  Members: 'groups-web.member',
  Students: 'groups-web.members.student',
  Coworkers: 'groups-web.members.coworker',
  Friends: 'groups-web.members.friend',
  Gamers: 'groups-web.members.gamer',
  Custom: 'groups-web.members.custom',
};
