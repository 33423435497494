import React from 'react';
import { WithTranslation, withTranslation } from '@wix/yoshi-flow-editor';
import { ImageLoader } from '../../../../common/components/ImageLoader/ImageLoader';
import { Theme } from '../../../../common/context/theme';

export interface GroupLogoUploadProps extends Theme {
  uploadImage(image: File): void;
  className?: string;
}

export interface GroupLogoUploadState {
  loading: boolean;
  logoUrl: string;
}

export class GroupLogoUploadComponent extends React.Component<
  GroupLogoUploadProps & WithTranslation,
  GroupLogoUploadState
> {
  render() {
    const { uploadImage, t, className, forceBlackAndWhite } = this.props;
    return (
      <ImageLoader
        forceBlackAndWhite={forceBlackAndWhite}
        uploadImage={uploadImage}
        uploadImageLabel={t('groups-web.modal.create-group.image.upload')}
        changeImageLabel={t('groups-web.modal.create-group.image.change')}
        deleteImageLabel={t('groups-web.modal.create-group.image.delete')}
        loadingLabel={t('groups-web.loading')}
        className={className}
      />
    );
  }
}

export const GroupLogoUpload = withTranslation()(
  GroupLogoUploadComponent,
) as React.ComponentType<GroupLogoUploadProps>;
