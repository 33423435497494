import React from 'react';
import { Theme } from './Theme';

export const ThemeContext = React.createContext<Theme>({
  forceBlackAndWhite: false,
});

ThemeContext.displayName = 'ThemeContext';
export const ThemeProvider = ThemeContext.Provider;
export const ThemeConsumer = ThemeContext.Consumer;
