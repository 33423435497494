import React from 'react';

import { AppToastsConsumer } from './AppToastsContext';
import { WithAppToastsProps } from './withAppToastsProps';

export const withAppToasts = <P extends object>(
  WrappedComponent: React.ComponentType<P>,
) => {
  return class WithAppToasts extends React.Component<P & WithAppToastsProps> {
    render() {
      return (
        <AppToastsConsumer>
          {(toasts) => {
            return <WrappedComponent {...this.props} toasts={toasts} />;
          }}
        </AppToastsConsumer>
      );
    }
  };
};
