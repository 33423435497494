import React from 'react';

export const SpinnerIcon: React.FC<React.SVGAttributes<SVGSVGElement>> = (
  props,
) => (
  <svg viewBox="0 0 20 20" {...props}>
    <path
      fill="none"
      stroke="currentColor"
      d="M530.45,660.38A8,8,0,1,0,536,668a8.06,8.06,0,0,0-.4-2.52"
      transform="translate(-518 -658)"
    />
  </svg>
);
