import { LogLevel } from './Logger';

export function getLogLevelFromLocation() {
  try {
    if (typeof window !== 'undefined' && window.URLSearchParams) {
      const params = new URLSearchParams(window.location.search);
      return (
        (params.get('debug') && LogLevel.LOG) ||
        (params.get('trace') && LogLevel.TRACE)
      );
    }
  } catch (e) {
    console.log('FAILED in getLogLevelFromLocation');
  }
  return LogLevel.NONE;
}
