// https://devforum.wix.com/en/article/wix-style-parameters

export enum FontTheme {
  HEADING_1 = 'Title',
  HEADING_2 = 'Page-title',
  HEADING_3 = 'Heading-XL',
  HEADING_4 = 'Heading-L',
  HEADING_5 = 'Heading-M',
  HEADING_6 = 'Heading-S',
  PARAGRAPH_1 = 'Body-L',
  PARAGRAPH_2 = 'Body-M',
  PARAGRAPH_3 = 'Body-S',
}
