export enum LogLevel {
  LOG = 'log',
  DEBUG = 'debug',
  TRACE = 'trace',
  NONE = 'none',
}
export interface Logger {
  log(...messages: any): void;
  mode: LogLevel;
}
