export enum Environment {
  VIEWER = 'Viewer',
  EDITOR = 'Editor',
  VIEWER_WORKER = 'Viewer:Worker',
  EDITOR_WORKER = 'Editor:Worker',
}

export const dsn =
  'https://59d70b5fe41b4c4e98199321f3ccb96e@sentry.wixpress.com/713';

export const createBoundaryConfig = (environment: Environment) => {
  return {
    dsn,
    config: {
      environment,
    },
  };
};
