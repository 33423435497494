import { Logger, LogLevel } from './Logger';
import { getLogLevelFromLocation } from './utils';

class ConsoleLoggerSingleton implements Logger {
  constructor(public mode: LogLevel) {}

  setModeFromLocation() {
    this.mode = getLogLevelFromLocation() as any;
  }
  log(...messages: any) {
    switch (this.mode) {
      case LogLevel.LOG:
        console.log(...messages);
        break;
      case LogLevel.TRACE:
        console.trace(...messages);
        break;
      case LogLevel.DEBUG:
        console.debug(...messages);
        break;
      default:
    }
  }
}

export const ConsoleLogger = new ConsoleLoggerSingleton(LogLevel.NONE);
