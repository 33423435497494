import React from 'react';
import {
  useSettings,
  useStyles,
} from '@wix/yoshi-flow-editor/tpa-settings/react';
import { getSettingsKeyFor, isUndefinedOrTrue } from '../../utils/utils';
import { settingsParams } from '../../settings/groups-list/settings';
import { widgetSettingsParams } from '../../settings/groups-list/widgetSettings';
import sideBySideSettings from '../../../components/SideBySide/settingsParams';
import sideBySideStyles from '../../../components/SideBySide/stylesParams';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { stylesParams } from '../../settings/groups-list/styles';
import { IGroupCardSettings } from '../../components/GroupCard/v2/IGroupCardSettings';
import {
  ImageRatio,
  LayoutType,
} from '../../settings/groups-list/settingsConstants';
import { WidgetContext } from '../../context/widget/WidgetContext';
import { useImageRatio } from './useImageRatio';
import { WidgetType } from '../../context/widget/WidgetType';

export const useGroupsSettings = () => {
  const settings = useSettings();
  const { isMobile } = useEnvironment();
  const styles = useStyles();
  const { type } = React.useContext(WidgetContext);
  let showMembersCount: boolean;
  let showGroupType: boolean;
  let gridCardSpacing: number;
  let showAdminBadge: boolean;
  const showButton = isUndefinedOrTrue(
    settings.get(widgetSettingsParams.showButton),
  );
  {
    const key = getSettingsKeyFor('showMemberCount', !!isMobile);
    showMembersCount = settings.get(settingsParams[key]);
    showGroupType = isUndefinedOrTrue(
      settings.get(widgetSettingsParams.showGroupType),
    );
  }
  {
    const k = getSettingsKeyFor('showAdminBadge', !!isMobile);

    showAdminBadge = settings.get(settingsParams[k]);
  }
  {
    const key = getSettingsKeyFor('gridCardSpacing', isMobile);
    gridCardSpacing = settings.get(settingsParams[key]);
  }

  const rawMobileGroupCardListSpacing = styles.get(
    stylesParams.mobileGroupCardListSpacing,
  );

  const mobileGroupCardListSpacing =
    rawMobileGroupCardListSpacing == null ? 0 : rawMobileGroupCardListSpacing;

  const sideBySideCardSpacing = styles.get(
    sideBySideStyles.sideBySideCardSpacing,
  );

  let groupListLayout: LayoutType;
  // TODO: think of a better pattern
  switch (type) {
    case WidgetType.GroupsPage:
      const layoutKey = getSettingsKeyFor('groupListLayout', isMobile);
      groupListLayout = settings.get(settingsParams[layoutKey]);
      break;
    case WidgetType.ListWidget:
      groupListLayout = settings.get(widgetSettingsParams.groupListLayout);
      break;
    case WidgetType.SideBySide:
      groupListLayout = settings.get(sideBySideSettings.groupListLayout);
      break;
  }

  const imageRatio: ImageRatio = useImageRatio(groupListLayout!)!;
  let cardHeight: number | undefined;
  if (groupListLayout! === LayoutType.sideBySide) {
    cardHeight = styles.get(stylesParams.cardSideBySideHeight);
  }

  const sk = getSettingsKeyFor('showImage', isMobile);
  const showImage = settings.get(settingsParams[sk]);
  const ck = getSettingsKeyFor('listCardSpacing', isMobile);
  const listCardSpacing = settings.get(settingsParams[ck]);

  const imageCrop = settings.get(widgetSettingsParams.imageCrop);
  const imageLayout = settings.get(widgetSettingsParams.imageLayout);
  const textAlignment = settings.get(widgetSettingsParams.textAlignment);
  const textSpacing = settings.get(widgetSettingsParams.textSpacing);

  return {
    showMembersCount,
    showGroupType,
    showAdminBadge,
    showButton,
    gridCardSpacing,
    mobileGroupCardListSpacing,
    groupListLayout: groupListLayout!,
    showImage,
    imageRatio,
    imageCrop,
    imageLayout,
    textAlignment,
    textSpacing,
    listCardSpacing,
    cardHeight,
    sideBySideCardSpacing,
  } as IGroupCardSettings;
};
