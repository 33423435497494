import React from 'react';
import { Dialog } from '../Dialog';
import { ModalProps } from '../Modal/Modal';
import { WithTranslation, withTranslation } from '@wix/yoshi-flow-editor';

interface ProfilePrivacyDialogProps extends ModalProps {
  onChangeProfile(): void;
}

const ProfilePrivacyDialogComponent = (
  props: ProfilePrivacyDialogProps & WithTranslation,
) => {
  const { t, onChangeProfile, ...rest } = props;
  const title = t('groups-web.join.profile');
  const text = t('groups-web.join.profile.text');
  const cancel = t('groups-web.cancel');
  const change = t('groups-web.join.profile.change');
  return (
    <Dialog
      title={title}
      caption={text}
      okLabel={change}
      cancelLabel={cancel}
      onOkClick={onChangeProfile}
      {...rest}
    />
  );
};

export const ProfilePrivacyDialog = withTranslation()(
  ProfilePrivacyDialogComponent,
);
ProfilePrivacyDialog.displayName = 'ProfilePrivacyDialog';
