import React from 'react';

export const Private: React.FC = (props) => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" {...props}>
    <g
      id="Icons/Lock"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <path
        d="M12,4 C14.6887547,4 16.8818181,6.12230671 16.9953805,8.78311038 L17,9 L17,11 L19,11 L19,20 L5,20 L5,11 L7,11 L7,9 C7,6.23857625 9.23857625,4 12,4 Z M18,12 L6,12 L6,19 L18,19 L18,12 Z M12,5 C9.85780461,5 8.10892112,6.68396847 8.00489531,8.80035966 L8,9 L8,11 L16,11 L16,9 C16,6.790861 14.209139,5 12,5 Z"
        fill="currentColor"
        fillRule="nonzero"
      />
    </g>
  </svg>
);
