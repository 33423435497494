interface Styles {
  [name: string]: string;
}
export class ClassNamesBuilder {
  private readonly rawClasses = new Set<string>();
  private readonly classes = new Set<string>();
  static fromProps(props: { [p: string]: any }) {
    const builder = new ClassNamesBuilder();
    builder.setClasses(props);
    return builder;
  }

  private setClasses(props: { [p: string]: any }) {
    for (const [key, value] of Object.entries(props)) {
      if (!value) {
        continue;
      }
      const v = typeof value === 'string' ? `-${value}` : '';
      const className = `${key}${v}`;
      this.rawClasses.add(className);
    }
  }

  withStyles(styles: Styles) {
    if (!styles) {
      return this;
    }
    this.rawClasses.forEach((className) => {
      if (styles[className]) {
        this.classes.add(styles[className]);
      }
    });
    return this;
  }

  addClassName(className?: string) {
    if (className) {
      this.classes.add(className);
    }
    return this;
  }

  build() {
    return Array.from(this.classes).join(' ');
  }
}
