/**
 * BlockAlign
 */
export enum BlockAlign {
  center = 'center',
  end = 'end',
  start = 'start',
  stretch = 'stretch',
}

export enum BlockPlace {
  evenly = 'space-evenly',
}

export enum BlockFlow {
  col = 'col',
  row = 'row',
}
